import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ElementType,
  forwardRef,
  HTMLAttributeAnchorTarget,
  ReactNode
} from 'react'
import styled, { CSSObject } from '@emotion/styled'
import { DefaultTheme } from 'assets/theme/theme'
import { margin, MarginProps, width, WidthProps } from 'styled-system'
import { AnimatedIcon } from '../../../shared/library/atoms/Icon'
import isPropValid from '@emotion/is-prop-valid'
import determineColorForHover from 'helpers/determineColorForHover'
import { rgba } from 'polished'
import useTranslation from 'next-translate/useTranslation'

export interface ButtonProps
  extends MarginProps,
    WidthProps,
    Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'type'>,
    // Allow href for when rendered as `<a>`
    Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  children?: ReactNode

  /**
   * Use the variant to change the color and style of the button.
   */
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'icon'
    | 'iconCircle'
    | 'eola-primary'
    | 'eola-secondary'
    | 'content'

  /**
   * If `true`, the button will be disabled but this prop is not named disabled because
   * other props such as `loading` and `success` also make the button disabled.
   */
  inactive?: boolean

  /**
   * Takes a function.
   *
   * I accepts any type of event because the button can be used by multiple elements such
   * as as simple button, anchor link and submit form.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event: any) => void

  /**
   * For indicating whether the button has triggered a process that needs to be waited on
   * e.g. when a user adds an item to the basket - we use this to show a loading spinner.
   * If `true`, the button will be disabled.
   */
  loading?: boolean

  /**
   * For indicating whether the button has triggered a process has completed on
   * e.g. when a form has been submitted and gets success.
   * If `true`, the button will be disabled.
   */
  success?: boolean

  /**
   * Allow the button be rendered as another ElementType, e.g. "span", "div", etc.
   */
  as?: ElementType

  /**
   * Allow target in case the button is rendered as an anchor tag.
   */
  target?: HTMLAttributeAnchorTarget

  /**
   * Add a data attribute for an identifier to be passed into google analytics
   * when clicked. This is used for tracking user interactions.
   */
  gaid?: string
}

/**
 * Some props are optional because the component has default values in case some
 * prop is not declared. All proprieties change to required here just to inform the
 * `styled component` that these values are always present.
 */

type StyledButtonProps = Required<Pick<ButtonProps, 'variant' | 'inactive'>> &
  Pick<ButtonProps, 'loading' | 'success' | 'target'> & {
    disabled: boolean
  }

const BUTTON_VARIANTS = ({
  theme,
  variant,
  loading,
  success,
  inactive,
  disabled
}: StyledButtonProps & { theme: DefaultTheme }): CSSObject => {
  const borderSize = 1
  const paddingY = theme.space[2]
  const paddingX = theme.space[4]
  const buttonPaddingWithoutBorder = `${paddingY - borderSize}px ${
    paddingX - borderSize
  }px` // Decrease the padding based on the border size. Purpose: Assure the primary and secondary buttons have the same height.

  const boxShadowOnInteraction = {
    boxShadow: theme.shadows.primary[1]
  }
  const boxShadowOnLoading = {
    boxShadow: theme.shadows.primary[0]
  }

  const primary = {
    padding: `${paddingY}px ${paddingX}px`,
    background: theme.colors.black,
    color: theme.colors.white,
    borderRadius: theme.radii[2],
    ...(loading && boxShadowOnLoading),
    ...(!disabled && {
      '&:hover, &:focus-visible': {
        // Not using theme.shadows becuase this is a special case
        boxShadow: `4px 4px 0 0 ${rgba(theme.colors.black, 0.12)}`
      }
    }),
    ...(inactive && {
      color: theme.colors.white,
      background: theme.colors.state.disabled
    })
  }

  const textVariation = {
    padding: 0,
    background: 'none',
    textDecoration: 'none',
    ...theme.fontStyle.p2,
    ...(!disabled && {
      '&:focus-visible': {
        color: theme.colors.text.secondary
      }
    }),
    ...(inactive && {
      color: theme.colors.state.disabled
    })
  }

  /**
   * Variants available for changing the color and style of the button.
   */
  switch (variant) {
    // Solid background
    case 'primary':
      return {
        ...primary
      }

    // Outlined
    case 'secondary':
      return {
        padding: buttonPaddingWithoutBorder,
        border: `${borderSize}px solid ${theme.colors.primary}`,
        background: 'transparent',
        color: theme.colors.primary,
        borderRadius: theme.radii[1],
        ...(loading && {
          ...boxShadowOnLoading,
          borderColor: theme.colors.white
        }),
        ...(success && {
          borderColor: theme.colors.white
        }),
        ...(!disabled && {
          '&:hover': {
            background: theme.colors.primary,
            ...boxShadowOnInteraction,
            color: theme.colors.white
          },
          '&:focus-visible': {
            ...boxShadowOnInteraction
          }
        }),
        ...(inactive && {
          color: theme.colors.white,
          background: theme.colors.state.disabled,
          borderColor: theme.colors.state.disabled
        })
      }

    // Just text with colour
    case 'tertiary':
      return {
        color: theme.colors.primary,
        ...textVariation,
        '&:hover': {
          color: determineColorForHover(theme.colors.primary)
        }
      }

    // Just grey text
    case 'quaternary':
      return {
        color: theme.colors.text.secondary,
        ...textVariation,
        '&:hover': {
          color: determineColorForHover(theme.colors.text.secondary)
        }
      }

    // Good for wrapping icons
    case 'icon':
    case 'iconCircle': //TODO: Use it in quantity component #4266
      return {
        overflow: 'initial',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        background: 'none',
        border:
          variant === 'icon'
            ? 'none'
            : `${borderSize}px solid ${theme.colors.primary}`,
        borderRadius: '50%',
        '&:hover': {
          ...theme.mixins.iconOnHover
        },
        ...(inactive && {
          '& svg use': {
            color: theme.colors.state.disabled
          }
        })
      }

    /**
     * Eola - Public Facing
     */

    // Solid background
    case 'eola-primary':
      return {
        ...primary,
        padding: theme.mixins.pxSpread([theme.space[4], theme.space[5]])
      }

    // Transparent
    case 'eola-secondary':
      return {
        padding: theme.mixins.pxSpread([theme.space[4], theme.space[5]]),
        background: 'transparent',
        color: theme.colors.eola.greys[70],
        borderRadius: theme.radii[2],
        ...(loading && boxShadowOnLoading),
        ...(!disabled && {
          '&:hover, &:focus-visible': {
            color: theme.colors.black,
            background: theme.colors.white
          }
        }),
        ...(inactive && {
          color: theme.colors.white,
          background: theme.colors.state.disabled
        })
      }

    // Outlined with radius
    case 'content':
      return {
        border: `${borderSize}px solid ${theme.colors.border.primary}`,
        borderRadius: theme.radii[3],
        padding: theme.mixins.pxSpread([theme.space[4], theme.space[3]]),
        background: 'transparent',
        color: theme.colors.primary,
        ...(!disabled && {
          '&:hover': {
            ...boxShadowOnInteraction,
            border: `${borderSize}px solid ${theme.colors.border.secondary}`
          },
          '&:focus': {
            ...boxShadowOnInteraction
          }
        }),
        ...(inactive && {
          border: `${borderSize}px solid ${theme.colors.border.secondary}`
        })
      }
  }
}

/**
 * Buttons allow users to take actions, and make choices, with a single tap.
 *
 * This forwardRef allows the component to receive a ref.
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = 'primary',
      inactive = false,
      loading, // Not giving a default value to don't use aria-busy if it is undefined
      success = false,
      onClick,
      target,
      gaid,
      ...other
    },
    ref
  ) => {
    const { t } = useTranslation('common')

    return (
      <StyledButton
        variant={variant}
        disabled={inactive || loading || success}
        inactive={inactive}
        loading={loading}
        aria-busy={loading}
        success={success}
        onClick={onClick}
        ref={ref}
        target={target}
        data-gaid={gaid}
        {...other}
      >
        {/* Wrap content with a span tag for styling purposes such as handling pseudo elements */}
        {variant === 'tertiary' ? (
          children
        ) : (
          <>
            {success && (
              <StyledAnimatedIcon name="check" autoplay freezeOnLastFrame />
            )}

            <StyledAnimatedIcon
              name="loader"
              autoplay
              loop
              isLoading={loading}
              aria-label={t('loading')}
            />
            <StyledContent>{children}</StyledContent>
          </>
        )}
      </StyledButton>
    )
  }
)
Button.displayName = 'Button'

export default Button

const StyledButton = styled('button', {
  /**
   * Filter out props that are not valid as html attributes.
   * This is necessary for preventing console warnings regarding the use of not valid html attributes.
   */
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'loading'
})(
  // Common styling
  ({ theme, disabled }) => ({
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-block',
    border: 'none',
    outline: 0,
    ...theme.fontStyle.h5,
    textDecoration: 'none',
    width: 'auto',
    pointerEvents: disabled ? 'none' : 'initial',
    cursor: disabled ? 'auto' : 'pointer',
    transition: `all ${theme.transitionTime[1]}s ease`
  }),
  // Variations
  BUTTON_VARIANTS,
  margin,
  width
)

const StyledContent = styled.span(() => ({
  position: 'relative' as const,
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

const StyledAnimatedIcon = styled(AnimatedIcon)<{ isLoading?: boolean }>(
  ({ theme, isLoading }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    display: isLoading ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    width: '105%', // using more than 100 to make sure it covers the whole element
    height: '105%',
    background: theme.colors.white,
    svg: {
      maxWidth: 30,
      maxHeight: 30
    }
  })
)
