
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { ApolloProvider } from '@apollo/client';
import { client } from 'helpers/apollo';
import { defaultTheme } from 'assets/theme/theme';
import useSetupLocale from 'state/useSetupLocale';
import AuthProvider from 'shared/providers/AuthProvider';
import ModalsGlobal from 'library/molecules/ModalsGlobal';
import GlobalStyle from 'assets/theme/GlobalStyle';
import 'intl-pluralrules'; // Intl.PluralRules fix for older versions of Safari - #4011
import BasketProvider from 'providers/BasketProvider';
import PageLoadedSignifier from 'shared/library/atoms/PageLoadedSignifier';
import dynamic from 'next/dynamic';
import { AppProps } from 'next/dist/shared/lib/router/router';
import PageRouterProvider from 'shared/providers/PageRouterProvider';
import ShouldAccess from 'library/molecules/ShouldAccess';
import { PROJECT } from 'enums/project';
import { MotionGlobalConfig } from 'framer-motion';
if (process.env.NODE_ENV === 'test' ||
    process.env.DISABLE_ANIMATIONS === 'true') {
    MotionGlobalConfig.skipAnimations = true;
}
const PageTracker = dynamic(() => import('library/molecules/PageTracker'));
function EolaApp({ Component, pageProps, err }: AppProps) {
    useSetupLocale();
    return (<>
      <ApolloProvider client={client}>
        <ThemeProvider theme={defaultTheme}>
          <AuthProvider project={PROJECT}>
            <BasketProvider>
              <PageLoadedSignifier />
              <GlobalStyle />
              <ModalsGlobal />
              <PageRouterProvider>
                <ShouldAccess>
                  <Component {...pageProps} err={err}/>
                </ShouldAccess>
              </PageRouterProvider>
              <PageTracker />
            </BasketProvider>
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>);
}
const __Next_Translate__Page__1919456d0fa__ = EolaApp;

    export default __appWithI18n(__Next_Translate__Page__1919456d0fa__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  