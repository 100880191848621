import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { space, SpaceProps } from 'styled-system'

interface CustomFontStyles {
  /**
   * Corresponds to font-family.
   */
  font?: 'heading' | 'body'
  /**
   * Corresponds to fonts-size.
   */
  size?: 'xxxl' | 'xxl' | 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  /**
   * Corresponds to font-weight.
   * Note - fonts of type 'heading' should not have'regular' font weights
   *      - the 'font' parameter auto handles the font-weight if they 'weight' is undefined.
   */
  weight?: 400 | 600
}

export interface DefaultFontStyles {
  /**
   * Corresponds to a configured fontStyle style.
   */
  fontStyle?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p1'
    | 'p2'
    | 'p3'
    | 'p4'
}

interface CommonTextStyles extends SpaceProps {
  color?: 'primary' | 'secondary' | 'tertiary' | 'white' | 'black'
  textAlign?: 'left' | 'center' | 'right' | 'inherit'

  /**
   * Set the default margins in the style of a traditional paragraph element (note - we use p tag
   * by default for our Text components but we hard set 0 to margins by default since in most cases
   * we've seen we only want the p tag for semantics and we don't necessarily want the margins seen
   * in traditional paragraphs)
   */
  paragraphMargins?: boolean
  /**
   * This is used in combination with aria-labelledby.
   *
   * @example
   * <dialog
   *  aria-labelledby={id + "-label"} <-- popupTitleId
   * >
   *  <div>
   *    <h2 id={id + "-label"}>{name}</h2> <-- id
   *    <button onClick={closePopup}>Close</button>
   *  </div>
   * </dialog>
   */
  id?: string
}

type TextStyles = CommonTextStyles & CustomFontStyles & DefaultFontStyles

interface TextProps extends TextStyles {
  /**
   * Children is optional to handle dangerouslySetInnerHTML
   */
  children?: ReactNode

  /**
   * Allows more props. corresponds to ...other
   */
  [propName: string]: unknown
}

/**
 * Default fontStyle elements such as H1, H2, etc.
 * It accepts the CommonTextStyles but don't allow custom configurations such as
 * font, size and weight. Use the Text component if you need to customise a heading element.
 */
export interface DefaultFontProps extends CommonTextStyles, DefaultFontStyles {
  /**
   * Children is optional to handle dangerouslySetInnerHTML
   */
  children?: ReactNode
}

/**
 * Text component. Used to facilitate all types of text.
 *
 * NOTE - this is utilised by the heading components - H1, H2, H3, H4, H5. If you need these elements for semantic
 * reasons please use them.
 *
 * ##'Font variations
 * We have taken the decision not to enforce this in the code so far as the tradeoff in complexity has been deemed
 * undesirable.
 * However we have made the following design decision that you should stick to as devs implementing text in the codebase
 *
 * ## Using Margins
 *
 * Important - only pass margin props through if the component is not doing what you need it to out the box
 * Things have been set up so that defaults should work a good portion of the time.
 *
 * If you want the component to appear like a traditional paragraph in terms on it's margins then you can use
 * the paragraphMargins prop (boolean). This will help us achieve consistency
 *
 * For other more nuanced use cases you have the option to use styled-system props or override existing styles
 * in the callee components (typically in an organism or page)
 */

const Text = ({
  children,
  paragraphMargins,
  fontStyle,
  ...other
}: TextProps) => {
  return (
    <StyledText
      fontStyle={fontStyle}
      my={paragraphMargins ? 3 : undefined}
      {...other}
    >
      {children}
    </StyledText>
  )
}

Text.Error = styled(Text)(({ theme }) => ({
  color: theme.colors.state.error,
  fontSize: theme?.fontSizes.xs
}))

export default Text

const StyledText = styled.p<TextStyles>(
  ({ theme, font = 'body', size, color, weight, fontStyle, textAlign }) => {
    // Determine if should load an established font style or have it manually customised
    const style = fontStyle
      ? theme?.fontStyle[fontStyle]
      : {
          // Defaults to P2 values if some prop is missing
          fontFamily: font
            ? theme?.fonts[font]
            : theme?.fontStyle.p2.fontFamily,
          fontSize: size
            ? theme?.fontSizes[size]
            : theme?.fontStyle.p2.fontSize,
          lineHeight: size
            ? theme?.lineHeights[size] + 'px'
            : theme?.fontStyle.p2.lineHeight,
          fontWeight: weight ?? theme?.fontWeights[font]
        }

    return {
      ...style,
      letterSpacing: theme?.letterSpacings[1],
      textAlign: textAlign,
      color: color ? theme?.colors.text[color] : 'currentColor',
      marginTop: 0,
      marginBottom: 0,
      /**
       * Our WYSWIG editor in the legacy business app uses 'ol' for all lists but indicates via the data-list attribute
       * whether something should be numbered or not (weird)
       * This makes sure that things are styled as expected when rendering as html via the text component
       */
      'li[data-list="bullet"]': {
        listStyleType: 'initial'
      }
    }
  },
  space
)
