import colors from './colors'
import generateMixins from 'shared/helpers/generateMixins'
export type DefaultTheme = typeof defaultTheme
export type CoreTheme = typeof coreDefaulTheme | typeof coreCustomTheme
import generateShadows from 'helpers/generateShadows'
import generateMediaQueries from 'shared/helpers/generateMediaQueries'
import { fontStyle, coreTypography } from 'assets/theme/typography'

type WidgetWidth = { widget: number }

// This is a special case only used to determining the widget max-width.
const widgetWidth: WidgetWidth = { widget: 720 }

export type Breakpoints = Record<
  'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl',
  number
> &
  WidgetWidth

export const breakpoints: Breakpoints = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1920,
  ...widgetWidth
}

export type ContentMaxWidth = {
  [keys in 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl']: number
} & WidgetWidth

const contentMaxWidth: ContentMaxWidth = {
  xs: 400,
  sm: 480,
  md: 600,
  lg: 780,
  xl: 1040,
  xxl: 1440,
  xxxl: 1600,
  ...widgetWidth
}

const shadows = {
  primary: {
    ...generateShadows(colors.primary)
  },
  black: {
    ...generateShadows('#000')
  }
}

/**
 * @desc core values for setting up the theme
 */
export const coreTheme = {
  initialColorModeName: 'light',
  ...coreTypography,
  fontStyle,
  space: [0, 4, 8, 12, 16, 24, 32, 40, 48, 64, 80, 128],
  transitionTime: [0, 0.2], // seconds
  transitionTimingFunction: {
    smooth: 'cubic-bezier(0.1, 0.75, 0.5, 0.95)'
  },
  radii: [0, 2, 4, 8, 16, 32],
  border: `1px solid ${colors.border.primary}`,
  shadows,
  // Centralize all values in one place to be easy to confirm what has priority
  zIndex: {
    bottombar: 10
  },
  breakpoints,
  mediaQueries: generateMediaQueries(breakpoints),
  /**
   * TODO #5565 - Make mediaQueries more flexible so we can pass the extra proprieties for max-width.
   * The customMediaQueries has been created just to prevent having to refactor all the files using
   * mediaQueries. The issue #5565 has been opened so we can refactor it on its own PR.
   */
  customMediaQueries: {
    minWidth: generateMediaQueries(breakpoints),
    maxWidth: generateMediaQueries(breakpoints, 'max-width')
  },
  contentMaxWidth,
  header: {
    height: 60
  },
  modal: {
    shadow: shadows.primary[2],
    margin: 0 // Represents theme.space[0]
  }
}

const coreDefaulTheme = {
  colors: {
    ...colors
  },
  ...coreTheme
}

/**
 * @desc exports theme using the default values
 */
export const defaultTheme = {
  ...coreDefaulTheme,
  ...generateMixins(coreDefaulTheme)
}

const coreCustomTheme = {
  colors: {
    ...coreDefaulTheme.colors,
    primary: '#096C00',
    gradients: {
      primary: 'radial-gradient(50% 50% at 50% 50%, #50C26A 0%, #096C00 100%)',
      secondary: 'linear-gradient(135DEG, #096C00 0%, #106708 100%)'
    }
  },
  ...coreTheme
}

/**
 * @desc exports theme using custom colors
 */
export const customTheme: DefaultTheme = {
  ...coreCustomTheme,
  ...generateMixins(coreCustomTheme)
}
